.select-field {
  @apply block w-full;
}

.select-field--invalid .select-field__control {
  border-color: #dc2626 !important;
}

.select-field--invalid .select-field__control.select-field__control--is-focused {
  border-color: #ef4444 !important;
}

.select-field--invalid .select-field__control:hover {
  border-color: #ef4444 !important;
}
