.loader {
  @apply z-10 flex items-center justify-center;
}

.loader--overlay {
  @apply absolute top-0 left-0 bottom-0 right-0 bg-gray-100 bg-opacity-50 animate-fade-in;
}

.loader--full {
  @apply fixed top-0 left-0 bottom-0 right-0 bg-gray-100 bg-opacity-50;
}

.loader--sm .loader__svg {
  @apply w-10 h-10;
}

.loader--md .loader__svg {
  @apply w-20 h-20;
}

.loader--lg .loader__svg {
  @apply w-32 h-32;
}

.loader--height-sm {
  @apply min-h-24;
}

.loader--height-md {
  @apply min-h-48;
}

.loader--height-lg {
  @apply min-h-60;
}

.loader__svg {
  @apply m-auto block;
  shape-rendering: auto;
}
