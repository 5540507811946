@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply h-full text-black;
}

label {
  @apply block cursor-pointer;
}

#root {
  @apply flex h-full;
}

hr {
  @apply border-t-2;
}

fieldset {
  border-style: groove;
  @apply border-t-2 border-secondary;
}

legend {
  @apply text-secondary pr-4 font-bold;
}

.content p {
  @apply mb-6;
}

.icon-card svg {
  width: auto;
  height: auto;
}
