.check-field {
  @apply flex items-center mb-2 last:mb-0 relative w-full;
}

.check-field--horizontal {
  @apply w-auto last:mb-2;
}

.check-field__title {
  @apply pl-9 flex-grow flex-shrink;
}

.check-field__input {
  @apply w-0 opacity-0 pointer-events-none;
}

.check-field__icon {
  @apply absolute flex w-5 h-5 pointer-events-none items-center justify-center flex-shrink-0;
}

.check-field__icon:before {
  content: '';
  @apply absolute block object-left-top inset-0 rounded border-2 border-gray-400;
}

.check-field__input:focus-visible + .check-field__icon:before {
  @apply border-gray-600;
}
