.radio-field {
  @apply flex mb-2 last:mb-0 items-center relative w-full;
}

.radio-field__title {
  @apply pl-9 flex-shrink;
}

.radio-field__input {
  @apply opacity-0 pointer-events-none;
}

.radio-field__icon:focus-within:before {
  @apply border-gray-600;
}

.radio-field__icon {
  @apply absolute w-5 h-5 pointer-events-none;
}

.radio-field__icon:before,
.radio-field__icon:after {
  content: '';
  @apply absolute block  object-left-top;
}

.radio-field__icon:before {
  @apply inset-0 rounded-full border-2 border-gray-400;
}

.radio-field--checked .radio-field__icon:after {
  @apply inset-1 rounded-full bg-blue-500;
}
