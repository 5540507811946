.popup-trigger {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.popup-container {
    z-index: 10;
    position: absolute;
    pointer-events: none;

    min-width: 300px;
    height: auto;

    background-color: white;
    border-radius: 1rem;
    border: 2px solid rgb(228, 228, 231);
    text-align: left;

    @apply shadow;
}

.popup-container.left {
    right: calc(100% + 20px);
    transform: translateY(-50%);
}

.popup-container.right {
    left: calc(100% + 20px);
    transform: translateY(-50%);
}

.popup-container.top {
    right: 50%;
    transform: translateX(50%);
    bottom: calc(100% + 20px);
}

.popup-container.bottom {
    right: 50%;
    transform: translateX(50%);
    top: calc(100% + 20px);
}

.popup-container > .popup-arrow {
    position: absolute;
    width: 24px;
    height: 24px;

    background: linear-gradient(135deg, transparent 50%, white 50%);
    border-bottom: 2px solid rgb(228, 228, 231);
    border-right: 2px solid rgb(228, 228, 231);
}

.popup-container.left > .popup-arrow {
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.popup-container.right > .popup-arrow {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(135deg);
}

.popup-container.top > .popup-arrow {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.popup-container.bottom > .popup-arrow {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(225deg);
}

.popup-title, .popup-items > * {
    @apply p-2;
}

.popup-container *:not(:last-child) {
    @apply border-b-2;
}

.popup-item {
    display: block;
    pointer-events: auto;
    @apply font-medium text-black;
}
