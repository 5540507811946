.quill {
  @apply rounded focus:shadow-border-focus shadow-border;
}

.ql-toolbar.ql-snow {
  @apply border-none;
  border-bottom: 1px solid #ccc;
}

.ql-container.ql-snow {
  @apply font-sans border-none;
}

.ql-tooltip {
  @apply z-10;
}

.ql-editor.ql-blank::before {
  @apply not-italic;
}

.ql-editor {
  min-height: 150px;
  resize: vertical;
}
